body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: #282c34;
  color: #ffffff;
  --column-width: 420px;
  --grid-gap: 50px;
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #ccc;
  --color-green: #3cc;
}

.woerterbuch {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--column-width), 1fr));
  justify-items: center;
  margin-top: var(--grid-gap);
  grid-gap: var(--grid-gap);
}

.woerterbuch > * {
  display: grid;
  grid-gap: var(--grid-gap);
  align-content: flex-start;
  grid-template-columns: var(--column-width);
}

h1,
h3 {
  margin: 0;
}

h1 {
  font-size: 3.2rem;
}

h3 {
  font-size: 1.8rem;
}

.wb-login,
.wb-add {
  display: grid;
  grid-gap: 10px;
}

select,
input,
button {
  background-color: var(--color-white);
  height: 48px;
  font-size: 1.5rem;
  padding: 0 10px;
}

.wb-view {
  display: grid;
  grid-gap: 20px;
}

.wb-word {
  display: grid;
  grid-gap: 5px;
}

.wb-meaning,
.wb-note {
  padding-left: 10px;
}

.wb-meaning {
  font-size: 1.2rem;
  font-weight: bold;
}

.wb-note {
  color: #ccc;
  font-size: 1rem;
}

.wb-capitalize {
  text-transform: capitalize;
}

.wb-word-type {
  font-size: 1rem;
  margin-left: 5px;
  color: var(--color-green);
  font-style: italic;
}

.wb-toggle {
  display: flex;
}

.wb-toggle label {
  height: 48px;
  display: inline-block;
  background: var(--color-white);
  flex: 1;
  color: var(--color-black);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wb-toggle input:checked + label {
  background: var(--color-gray);
  -moz-box-shadow: inset 0 0 10px var(--color-black);
  -webkit-box-shadow: inset 0 0 10px var(--color-white);
  box-shadow: inset 0 0 10px var(--color-black);
}

.wb-toggle input {
  display: none;
}
